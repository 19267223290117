@charset "utf-8";
// see https://mycolor.space/?hex=%23FFC600&sub=1
// linked from https://websitesetup.org/website-color-schemes/
// from colour: FFC600

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

// Set your brand colors
$yellow: #ffc600;
$lime-green: #95bf33;
$green: #30a862;
$forest-green: #008a7c;
$pine-green: #006978;
$dark-grey-green: #2f4858;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$primary: $yellow;
$link: $green;
$widescreen-enabled: false;
$fullhd-enabled: false;
$info: $pine-green;

// Update some of Bulma's component variables
//$body-background-color: $yellow;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

// Import only what you need from Bulma
//@import "../../node_modules/bulma/sass/utilities/_all.sass";
//@import "../../node_modules/bulma/sass/base/_all.sass";
// @import "../../node_modules/bulma/sass/elements/button.sass";
// @import "../../node_modules/bulma/sass/elements/container.sass";
// @import "../../node_modules/bulma/sass/elements/title.sass";
// @import "../../node_modules/bulma/sass/form/_all.sass";
// @import "../../node_modules/bulma/sass/components/navbar.sass";
// @import "../../node_modules/bulma/sass/layout/hero.sass";
// @import "../../node_modules/bulma/sass/layout/section.sass";
@import "../../node_modules/bulma/bulma.sass";
